<script lang="ts">
  import { onDestroy } from 'svelte'
  import { Modal } from 'carbon-components-svelte'
  import { state } from '../../store/state'
  import { noop } from 'common/src/utils'

  export let danger = false
  export let open = false
  export let modalHeading = ''
  export let text = ''
  export let primaryText = ''
  export let secondaryText = ''
  export let slotComponent: any = undefined
  export let slotProps: any = {}
  export let primaryButtonDisabled = false

  let subscriptions: (() => void)[] = []

  export let onSecondaryClick = () => {
    open = false
  }
  export let onPrimaryClick = () => {
    open = false
  }

  const modalUnsubscribe = state.modal.subscribe((m) => {
    subscriptions.forEach((s) => s())

    const confirm = m.primaryAction || { subscribe: noop }
    const cancel = m.secondaryAction || { subscribe: noop }

    subscriptions.push(
      m.heading.subscribe((value) => {
        modalHeading = value
      }),
      m.primaryButtonDisabled.subscribe((value) => {
        primaryButtonDisabled = value
      }),
      m.danger.subscribe((value) => {
        danger = value
      }),
      m.text.subscribe((value) => {
        text = value
      }),
      m.primaryText.subscribe((value) => {
        primaryText = value
      }),
      m.secondaryText.subscribe((value) => {
        secondaryText = value
      }),
      cancel.subscribe((value) => {
        // open = false
        onSecondaryClick = value
      }),
      confirm.subscribe((value) => {
        // open = false
        onPrimaryClick = value
      })
    )

    slotComponent = m.slotComponent
    slotProps = m.slotProps
    open = m.open
  })

  onDestroy(() => {
    subscriptions.forEach((s) => s())

    modalUnsubscribe()
  })
</script>

<Modal
  size="m"
  bind:danger
  bind:open
  bind:modalHeading
  bind:primaryButtonText={primaryText}
  bind:secondaryButtonText={secondaryText}
  bind:primaryButtonDisabled
  on:click:button--secondary={onSecondaryClick}
  on:click:button--primary={onPrimaryClick}
  on:open
  on:close
>
  <div class="root">
    <p>
      {text}
    </p>
    <div>
      {#if slotComponent}
        <svelte:component this={slotComponent} {...slotProps} />
      {/if}
    </div>
  </div>
</Modal>

<style>
  .root {
    padding: 1rem;
  }
  :global(.bx--modal-content),
  :global(.bx--modal-container) {
    overflow: visible;
  }
</style>
